import React from 'react';
import ReactDOM from 'react-dom/client';

// CSS
import './assets/css/font-awesome.min.css'
import './assets/css/template.css'

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (
    <App />
)