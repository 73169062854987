import React, { useState,useEffect } from 'react';

const RadioBlock = (props) => {

   const [text, setText] = useState('');

    useEffect(() => {
        setText(props.value)
    }, [props.value]);
    const handleRadio = (e) => {
        // console.log(e.target.name)
        // console.log(e.target.value)

        setText(e.target.value);
        props.handleRadio(e);
    }
    // console.log(props.data)
    return (
        <>
        {props.data !== undefined && props.data.map((list,ix)=>
        <div className='csRadio' key={`a-s${ix}-${props.typ}`}>
            <input type='radio' id={`${props.name}-${ix}-${props.typ}`} className='csRadio__input'
                   checked={text === list }
                   value={list} name={props.name+'-'+props.typ}
                   onChange={(e)=>handleRadio(e)}
            />
            <span className='csRadio__placeholder' />
            <label htmlFor={`${props.name}-${ix}-${props.typ}`} className='csRadio__label'>{list}</label>
        </div>
            )}
            </>
    )
}

export default RadioBlock
