import axios from 'axios';

export const commonService = {
    sendHttpRequest,
};
function sendHttpRequest(URL, data, method) {
    var date = new Date();
    var timestamp = date.getTime();
    var params = '';
    if (method === 'GET' && data) {
        params = data;
    }
    let param = '';
    let statikKey = 'VVRKR2JGcHRaREJPUkZVeFRsUlVUSkdiRnB0WkRCT1JGVXhUbFJGZUU1cVZXaE5la2t3VGxoS2IxcEhXbTVqZWxWNlRrUldaVXBwY0dWTFExbHdTME5WYkZvelRtdGFNMDVyV25rMWJHUXlWbkZoTW1Sb1lUTktiMWxSUFQwPUZlRTVxVldoTmVra3dUbGhLYjFwSFdtNWplbFY2VGtSV1pVcHBjR1ZMUTFsd1MwTlZiRm96VG10YU0wNXJXbmsxYkdReVZuRmhNbVJvWVROS2IxbFJQVDA9PT0';
    return axios({
        method: method,
        url: "https://bsynapir4.bsynapse.com/"+ URL + '?t=' + timestamp + '&index=' + "demosg",
        data: data,
        params: params,
        headers : {'Content-Type' : 'application/json','X_API_KEY':statikKey},

        async: false,
    })
        .then(function (res) {
            return {
                msg: '',
                code: res.status,
                res: res.data,
            };
        })
        .catch(function (error) {
            console.log(error);
            if (error.response) {
                if (
                    error.response.status === 401 &&
                    error.response.data.error.message === 'Unauthorized'
                ) {
                    param = {
                        msg: 'Your user session has expired. Click ok to relogin.',
                        code: 401,
                        res: '',
                    };
                }
                if (error.response.status === 500) {
                    param = {
                        msg: 'Something Went wrong.',
                        code: error.response.status,
                        res: '',
                    };
                }
            } else {
                param = {
                    msg: 'There is a network failure, please try again.',
                    code: '12023',
                    res: '',
                };
            }

            return param;
        });
}
